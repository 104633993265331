import React, { useState, useRef, useEffect, useContext } from 'react';
import { TextField, Button, Grid, Typography, Container, Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import emailConfirmationImage from '../../../../_metronic/assets/img/email-confirmation.png';
import { GetProfileRequest, LoginRequest } from '../../../../api/endpoints/auth';
import { GlobalState } from '../../../contexts/AuthContext';
import { authSelector, resentOtpAction } from '../../../reducers/authReducer';
import { setAuth } from '../core/AuthHelpers';
import { useAppDispatch } from '../../../store';
import { toast } from 'react-toastify';



interface LocationState {
  accessToken: string;
  data: {
    username: string;
    password: string;
    grant_type: string;
    client_id: number;
    client_secret: string;
  };
}


const OtpVerify: React.FC = () => {

  const [inputs, setInputs] = useState(['', '', '', '']);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const state = location.state as LocationState;

  const handleInputChange = (e: any, index: number) => {
    const value = e.target.value;
    if (/^\d?$/.test(value)) {
      const newInputs = [...inputs];
      newInputs[index] = value;
      setInputs(newInputs);
      if (value && index < 3) {
        inputRefs.current[index + 1]?.focus();
      }
      else if (value === '' && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").trim();
    if (/^\d{4}$/.test(pasteData)) {
      const newInputs = pasteData.split("");
      setInputs(newInputs);
      inputRefs.current[3]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === 'ArrowLeft' && index > 0) {
      const prevInput = inputRefs.current[index - 1];
      if (prevInput) {
        prevInput.focus();
        prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length);
      }
    } else if (e.key === 'ArrowRight' && index < 3) {
      const nextInput = inputRefs.current[index + 1];
      if (nextInput) {
        nextInput.focus();
        nextInput.setSelectionRange(nextInput.value.length, nextInput.value.length);
      }
    }
  };


  useEffect(() => {
    if (!state?.data) {
      navigate('/auth')
    }
  },)


  const { timer } = useSelector(authSelector) ?? "0 minutes";
  console.log("timer from Redux:", timer);
  const storedTime = localStorage.getItem("Timer");
  const [time, setTime] = useState<number>(() => {
    return storedTime ? parseInt(storedTime) : parseInt(String(timer).replace(/\D/g, "")) * 60;
  });

  useEffect(() => {
    if (timer > 0) {
      const updatedTime = parseInt(String(timer).replace(/\D/g, "")) * 60;
      setTime(updatedTime);
      localStorage.setItem("Timer", updatedTime.toString());
    }
  }, [timer]);


  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem("Timer", newTime.toString());
          return newTime;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [time]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const isVerifyButtonDisabled = inputs.some(input => input.trim() === '') || time === 0;
  const isResendButtonDisabled = time > 0

  const globalState: any = useContext(GlobalState);

  const [loading, setLoading] = useState(false);


  const handleVerify = async () => {
    try {
      setLoading(true);
      const Code = inputs.join("");
      const loginModel = {
        otp: Code,
        grant_type: state.data.grant_type,
        client_id: state.data.client_id,
        client_secret: state.data.client_secret,
        username: state.data.username,
        password: state.data.password,
      };

      console.log("loginModel", loginModel);
      const res: any = await LoginRequest(loginModel);
      console.log("API Response:", res);

      if (res?.data?.access_token) {
        const profile: any = await GetProfileRequest(res?.data?.access_token);

        setAuth({
          access_token: res.data.access_token,
          refresh_token: res.data.access_token,
        });

        globalState.setGlobalState({
          ...globalState,
          isLoggedIn: true,
        });

        if (profile?.data) {
          globalState.setGlobalState({
            ...globalState,
            currentUser: profile?.data?.data,
          });
        }
      }
    } catch (error: any) {
      console.log("Full Error Object:", error);

      let errorMessage = "Something went wrong. Please try again.";

      if (error?.data?.error) {
        console.log("Error Response:", error.data.error);

        if (error?.data?.error) {
          errorMessage = error.data.error;
        }
      } else if (error?.message) {
        errorMessage = error.message;
      }
      toast.error(errorMessage, { position: "bottom-right", autoClose: 1000 });
    } finally {
      setLoading(false);
    }
  };


  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const [resendLoading, setResendLoading] = useState(false);

  const handleResentOtp = () => {
    setResendLoading(true);
    if (!state?.data?.username) {
      console.error("Email (username) is undefined.");
      setLoading(false);
      return;
    }
    dispatch<any>(resentOtpAction({ email: state.data.username }))
      .unwrap()
      .then((response: { success: boolean; expires_in_minutes: number }) => {
        if (response.success) {
          handleClick();

          const newTime = response.expires_in_minutes * 60;
          setTime(newTime);
          localStorage.setItem("Timer", newTime.toString());
        }
      })
      .catch((error: any) => {
        console.error("Error resending OTP:", error);
        toast.error(error?.message || "Failed to resend OTP", { position: "top-right" });
      })
      .finally(() => {
        setResendLoading(false);
      });
  };




  return (

    <Container maxWidth="xs" sx={{ textAlign: 'center' }}>

      <div className="img-verify">
        <img src={emailConfirmationImage} className="email-confirmation" alt="Otp Confirmation" />
      </div>

      <Typography variant="h4" gutterBottom>OTP Verification</Typography>
      <Typography variant="body1" gutterBottom sx={{ mb: 3 }}>
        We have sent you a 4-digit code to your Email Address. Please enter the code below:
      </Typography>

      <Grid container justifyContent="center" spacing={3}>
        {inputs.map((input, index) => (
          <Grid item key={index}>
            <TextField
              inputRef={(el) => (inputRefs.current[index] = el)}
              value={input}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => { handleKeyDown(e, index) }}
              onPaste={handlePaste}
              type="text"
              placeholder="0"
              inputProps={{
                maxLength: 1,
                style: { textAlign: 'center', fontSize: '24px', width: '40px', height: '20px' },
              }}
            />
          </Grid>
        ))}
      </Grid>

      <Typography variant="h6" sx={{ mt: 3 }}>
        {formatTime(time)}
      </Typography>

      {time === 0 && (
        <Typography variant="body1" color="error" sx={{ mb: 2, fontWeight:'bold' }}>
          Time is out, please Resend the code.
        </Typography>
      )}

      <Button
        variant="contained"
        onClick={handleVerify}
        disabled={isVerifyButtonDisabled}
        className='btn btn-lg btn-primary w-100 mb-5'
        sx={{
          mt: 2,
          padding: 1,
          "&:hover": {
            backgroundColor: "#04bac7",
          },
          "&:focus": {
            backgroundColor: "#04bac7",
          },
          "&:active": {
            backgroundColor: "#04bac7",
          },
        }}
      >

        {!loading && <span className='indicator-label'>Continue</span>}
        {loading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}

      </Button>

      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <button
          disabled={isResendButtonDisabled}
          onClick={handleResentOtp}
          style={{
            color: isResendButtonDisabled ? 'gray' : '#04bac7',
            background: 'none',
            border: 'none',
            padding: 0,
            cursor: isResendButtonDisabled ? 'default' : 'pointer',
          }}
        >
          {!resendLoading && <span >Resend Code</span>}
          {resendLoading && (
            <span>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>


      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        message={
          <span style={{ fontSize: "13px"}}>
            OTP resent successfully!
          </span>
        }
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      />



    </Container>
  );
};

export default OtpVerify;