import { useEffect } from "react";
import { Navigate, Routes } from "react-router-dom";
import { removeAuth } from "./core/AuthHelpers";

export function Logout() {
  useEffect(() => {
    removeAuth(); 
  }, []);

  return (
    <Routes>
      <Navigate to="/auth/login" />
    </Routes>
  );
}
