import client from '../client/client'
import qs from 'qs'

  const LoginRequest = async (data: any) => {
    try {
      const response = await client.post('oauth/token', qs.stringify(data), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      });
      return response; 
    } catch (error: any) {
      if (error.response) {
        throw error.response; 
      }
      throw new Error("Network error. Please try again."); 
    }
  };
  

const GetProfileRequest = async (token: any) =>
  await client.get('api/v1/admin', {
    headers: {Authorization: `Bearer ${token}`},
  })

const UpdateProfileRequest = async (id: any, data: any) =>
  await client.put(`api/v1/admin/${id}`, data)

const DeleteProfileRequest = async (id: any) => await client.delete(`api/v1/admin/delete/${id}`)

const RegistrationRequest = async (data: any) =>
  await client.post('api/v1/admin/registration', data)

const adminPasswordUpdateRequest = async (data: any) =>
  await client.post('api/v1/admin/reset-password', data)

const resentOtpRequest = async (data: { email: string }) =>
  await client.post('api/v1/admin/resent-otp-email', { email: data.email });


export {
  LoginRequest,
  RegistrationRequest,
  GetProfileRequest,
  UpdateProfileRequest,
  DeleteProfileRequest,
  adminPasswordUpdateRequest,
  resentOtpRequest
}
